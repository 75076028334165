<template>
	<div class="flex" style="flex-direction: column;height: 100%;">
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>数据列表</span>
				</div>
				<div class="title-right">
					<el-button type="primary" size="medium" @click="insert()" style="margin-right: 8px;">
						新增角色
					</el-button>
					<el-select v-model="searchParm.size" size="medium" :popper-append-to-body="false" placeholder="显示条数"
						@change="list()">
						<el-option label="5" :value="5"></el-option>
						<el-option label="10" :value="10"></el-option>
						<el-option label="20" :value="20"></el-option>
						<el-option label="50" :value="50"></el-option>
						<el-option label="100" :value="100"></el-option>
					</el-select>
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table ref="table" :data="tableData.records" border :height="tableHeight"
							:header-cell-style="{'background-color':'#f9fafc'}" :cell-style="{'padding':0}"
							style="margin-bottom: 20px;">
							<el-table-column prop="name" label="角色名称" show-overflow-tooltip>
							</el-table-column>
							<el-table-column fixed="right" label="操作" width="460">
								<template slot-scope="scope">
									<el-button type="text" @click="update(scope.row)">
										编辑权限
									</el-button>
									<el-button type="text" @click="remove(scope.row)">
										删除
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination style="float: right;" @current-change="handleCurrentChange"
						:current-page.sync="searchParm.current" :page-size="searchParm.size"
						layout="total, prev, pager, next,jumper" :total="tableData.total">
					</el-pagination>
				</div>
			</div>
		</div>

		<edit :isShow.sync="showEditDialog" :dialogObj="editObj"></edit>

	</div>
</template>
<script>
	import edit from "./edit.vue"
	export default {
		components: {
			edit
		},
		data() {
			return {
				tableHeight: 0,
				tableData: {
					records: [],
				},
				searchParm: {
					size: 20,
					current: 1
				},
				showEditDialog: false,
				editObj: {},
			}
		},
		methods: {
			//自适应表格高度
			calHeight() {
				this.$nextTick(() => {
					const rect = this.$refs.tableContainer.getBoundingClientRect()
					this.tableHeight = rect.height
				})
			},
			//清空搜索
			clearSearch() {
				this.searchParm = {
					size: 20,
					current: 1
				}
			},
			//展示数据
			list() {
				this.$get("/user-api/plat/role/list", this.searchParm).then((res) => {
					if (res.code == 1000) {
						this.tableData = res.data
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//打开添加弹窗
			insert() {
				this.editObj = {};
				this.editObj["type"] = 2;
				this.showEditDialog = true;
			},
			//打开编辑弹窗
			update(row) {
				this.editObj = {};
				this.editObj["type"] = 1;
				this.editObj["id"] = row.id;
				this.showEditDialog = true;
			},
			remove(row) {
				this.$confirm("是否确认删除？", "删除角色", {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post("/user-api/plat/role/delete", {
						id: row.id
					}).then((res) => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				}).catch(() => {});
			},
			//分页选择页面回调
			handleCurrentChange(val) {
				this.searchParm.current = val;
				this.list();
			},
		},
		mounted() {
			this.list();
			this.calHeight();
			window.addEventListener('resize', this.calHeight)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.calHeight)
		},
	};
</script>
<style lang='scss' scoped>
	@import "@style/userCenter.scss"
</style>
