<template>
	<el-dialog class="dialog" title="添加/编辑权限" :visible.sync="visiable" width="30%">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" label-width="120px">
				<el-form-item label="名称：">
					<el-input v-model="name"></el-input>
				</el-form-item>
				<el-form-item label="角色权限">
					<el-tree ref="tree" :data="permData" show-checkbox node-key="id" :default-checked-keys="checkdKey"
						:props="{
						children: 'child',
						label: 'name'
					}">
					</el-tree>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				loading: false,
				id: "",
				name: "",
				permData: [],
				checkdKey: []
			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.permData = [];
				this.checkdKey = [];
				this.name = "";
				this.id = "";
				this.getPermission();
				this.get();
			},
			getPermission() {
				this.$get("/user-api/plat/menu/tree", {}).then((res) => {
					this.permData = res.data
				});
			},
			get() {
				if (this.dialogObj.type == 1) {
					this.$get("/user-api/plat/role/get", {
						'id': this.dialogObj.id
					}).then((res) => {
						if (res.code == 1000) {
							this.id = res.data.id;
							this.name = res.data.name;
							this.checkdKey = res.data.menuIds;
						} else {
							this.$message.error(res.msg);
						}
					});
				}
			},
			//提交表单
			submit() {
				this.loading = true;
				var arr = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys());

				if (this.dialogObj.type == 1) {
					this.$postData("/user-api/plat/role/add", {
						id: this.dialogObj.id,
						name: this.name,
						menuIds: arr
					}).then((res) => {
						if (res.code == 1000) {
							this.loading = false;
							this.$message.success(res.msg);
							this.$parent.list();
							this.close();
						} else {
							this.$message.error(res.msg);
						}
					});
				} else if (this.dialogObj.type == 2) {
					this.$postData("/user-api/plat/role/add", {
						name: this.name,
						menuIds: arr
					}).then((res) => {
						if (res.code == 1000) {
							this.loading = false;
							this.$message.success(res.msg);
							this.$parent.list();
							this.close();
						} else {
							this.$message.error(res.msg);
						}
					});
				}
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep.cascader {
			display: block;
		}
	}
</style>
